import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useLocation } from 'react-router-dom';
import Sidebar from '../sidebars/sidebar3';
import Dropdown from 'react-bootstrap/Dropdown';
import { logout } from '../services/auth';
import { useNavigate } from 'react-router-dom/dist';
import { API_BASE_URL } from '../constants/apiConfig';
import { useAuth } from '../services/AuthProvider';


const LayoutPage = ({ children }) => {
    const [sidebarOpen, setSidebarOpen] = useState(true);

    const { user, hasPermission } = useAuth();
    const userName = user?.username || 'User';

    const location = useLocation();
    const isAddGardenFormPage = location.pathname === '/addGardenForm';
    const isQrGeneratorPage = location.pathname === '/qrGenerator';

    const navigate = useNavigate();

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen); // Toggle the sidebar state
    };


    const sidebarStyle = {
        width: sidebarOpen ? '20%' : '0',
        display: sidebarOpen ? 'block' : 'none',
        right: 0, // Set the right property to 0 for right-aligned sidebar


    };

    const mainStyle = {

        marginLeft: sidebarOpen ? '250px' : '0',
        height: '75vh',
        overflowY: 'auto',
    };

    const handleLogout = async (e) => {
        e.preventDefault();

        const USER_ID_KEY = 'user_id';
        const userId = localStorage.getItem(USER_ID_KEY);
        console.log(userId);

        try {
            const response = await fetch(`${API_BASE_URL}logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });

            if (response.ok) {
                logout();
                navigate('/');
            }
        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    };



    return (
        <div className='base_fixed'>


            <div className="d-flex top_bar mt-3 mb-3">

                <img
                    src="/images/mardi_logo.png"
                    className="img-fluid rounded-start mardi_logo_layout"
                    alt="..."
                />
                <h2 className="big_title">TAMAN AGROTEKNOLOGI MARDI CAMERON HIGHLANDS</h2>
            </div>

            {/* <Navbar bg="dark" data-bs-theme="dark" className=''>
                <div className='justify-content-start navbar_layout'>
                    <Navbar.Brand href="#home" className='text-white'>BORANG TAMAN AGROTEKNOLOGI</Navbar.Brand>
                    <Navbar.Brand href="#home" className='text-white'>QR Generator</Navbar.Brand>

                </div>
            </Navbar> */}

            <div className='bg-dark justify-content-between  d-flex flex-wrap'>
                <div className='d-flex flex-wrap navbar_layout '>
                    <button className="w3-button w3-teal" onClick={toggleSidebar}>&#9776;</button>
                    {/* <div className='navBtn'> <a href='/addGardenForm' className={`${isAddGardenFormPage ? 'active text-white navBar text-left' : 'navBar text-left'}`}>BORANG TAMAN AGROTEKNOLOGI</a></div>
                    <div className='navBtn'> <a href='/qrGenerator' className={`${isQrGeneratorPage ? 'active text-white navBar' : 'navBar'}`}>
                        QR <img
                            src="/images/qrcode_icon.png"
                            className="img-fluid qr_icon"
                            alt="..."
                        /> Generator
                    </a></div> */}
                </div>

                <div className=' d-flex justify-content-center align-items-center '>
                    {/* <a href='' className=' text-white  text-center  welcome_text'>Selamat Datang, Admin</a> */}
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            Selamat Datang, {userName}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="custom-dropdown-menu">
                            <Dropdown.Item onClick={handleLogout}>Log Keluar</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

            </div>

            <div className='qrPage'>

                <Sidebar sidebarOpen={sidebarOpen} closeSidebar={toggleSidebar} />
                <div id="main" className='qrPage' style={mainStyle}>

                    {/* <div className="w3-teal">
                        <button className="w3-button w3-teal w3-xlarge" onClick={openSidebar}>&#9776;</button>
                    </div> */}
                    {children}
                </div>
            </div>

        </div>
    )
}

export default LayoutPage