// import React from 'react';
import Form from 'react-bootstrap/Form';
import React, { useEffect } from 'react';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logout } from '../services/auth';
import { useNavigate } from 'react-router-dom/dist';
import { API_BASE_URL } from '../constants/apiConfig';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../services/AuthProvider';

const Sidebar = ({ sidebarOpen, closeSidebar }) => {

    const { user, hasPermission } = useAuth();

    const canManageUser = hasPermission('manage-user');
    const canManageRole = hasPermission('manage-role');
    const canManageZone = hasPermission('manage-zone');
    const canManageGarden = hasPermission('manage-garden');

    const navigate = useNavigate();
    const location = useLocation();
    const handleLogout = async (e) => {
        e.preventDefault();

        const USER_ID_KEY = 'user_id';
        const userId = localStorage.getItem(USER_ID_KEY);
        console.log(userId);

        try {
            const response = await fetch(`${API_BASE_URL}logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });

            if (response.ok) {
                logout();
                navigate('/');
            }
        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    };

    // Function to determine if the link is active
    const isActive = (path) => {
        return location.pathname === path;
    };

    useEffect(() => {
        const handleNavClick = (e) => {
            const element = e.target;
            const nextEl = element.nextElementSibling;
            const parentEl = element.parentElement;

            if (nextEl) {
                e.preventDefault();
                const mycollapse = new window.bootstrap.Collapse(nextEl);

                if (nextEl.classList.contains('show')) {
                    mycollapse.hide();
                } else {
                    mycollapse.show();
                    // Find other submenus with class=show
                    const openedSubmenu = parentEl.parentElement.querySelector('.submenu.show');
                    // If it exists, close all of them
                    if (openedSubmenu) {
                        new window.bootstrap.Collapse(openedSubmenu);
                    }
                }
            }
        };
        document.querySelectorAll('.sidebar .nav-link').forEach((element) => {
            element.addEventListener('click', handleNavClick);
        });

        return () => {
            document.querySelectorAll('.sidebar .nav-link').forEach((element) => {
                element.removeEventListener('click', handleNavClick);
            });
        };
    }, []);

    const sidebarStyle = {
        width: sidebarOpen ? '250px' : '0',
        display: sidebarOpen ? 'block' : 'none',
        left: 0, // Set the right property to 0 for right-aligned sidebar


    };
    const sidebarStyle2 = {
        width: sidebarOpen ? '300px' : '0',
        display: sidebarOpen ? 'block' : 'none',
        left: 0, // Set the right property to 0 for right-aligned sidebar


    };

    return (
        <div>
            <div className="w3-sidebar w3-bar-block w3-card w3-animate-right sidebarqr" style={sidebarStyle} id="mySidebar">

                {/* <button className="w3-bar-item w3-button w3-large" onClick={closeSidebar}>Close &times;</button> */}
                <div className='sidebar_headerqr'>
                    {/* <label className='bold_poppins text-black'>History</label> */}
                    <div className='sidebar_scroll2'>
                        {/* Use the isActive function to add the 'active' class conditionally */}
                        <div className={isActive('/dashboard') ? 'mb-2 menu_active' : 'mb-2'}>
                            <a className="text-white regular_poppins text-decoration-none" href="/dashboard">Dashboard</a>
                        </div>
                        {canManageZone && <div className={isActive('/zone') ? 'mb-2 menu_active' : 'mb-2'}>
                            <a className="text-white regular_poppins text-decoration-none" href="/zone">Zon</a>
                        </div>}
                        {canManageGarden && <div className={isActive('/taman') ? 'mb-2 menu_active' : 'mb-2'}>
                            <a className="text-white regular_poppins text-decoration-none" href="/taman">Taman</a>
                        </div>}
                        {canManageRole && <div className={isActive('/role') ? 'mb-2 menu_active' : 'mb-2'}>
                            <a className="text-white regular_poppins text-decoration-none" href="/role">Peranan</a>
                        </div>}
                        {canManageUser && <div className={isActive('/user') ? 'mb-2 menu_active' : 'mb-2'}>
                            <a className="text-white regular_poppins text-decoration-none" href="/user">Pengguna</a>
                        </div>}
                    </div>

                </div>
                {/* <div className='sidebar_bodyqr'>
                    <label className='bold_poppins text-black'>SENARAI TUGASAN</label>
                    <div className="sidebar_scroll">
                        <Form>
                            {['checkbox'].map((type) => (
                                <div key={`default-${type}`} className=" text-white">
                                    <Form.Check // prettier-ignore
                                        type={type}
                                        id={`default-${type}`}
                                        className='mb-2'
                                        // label={`default ${type}`}
                                        label={`Tugasan 1`}
                                    />
                                    <Form.Check // prettier-ignore
                                        type={type}
                                        id={`default-${type}`}
                                        className='mb-2'
                                        label={`Tugasan 3`}
                                    />
                                    <Form.Check // prettier-ignore
                                        type={type}
                                        id={`default-${type}`}
                                        className='mb-2'
                                        label={`Tugasan 4`}
                                    />
                                    <Form.Check // prettier-ignore
                                        type={type}
                                        id={`default-${type}`}
                                        className='mb-2'
                                        label={`Tugasan 5`}
                                    />
                                    <Form.Check // prettier-ignore
                                        type={type}
                                        id={`default-${type}`}
                                        className='mb-2'
                                        label={`Tugasan 8`}
                                    />
                                    <Form.Check // prettier-ignore
                                        type={type}
                                        id={`default-${type}`}
                                        className='mb-2'
                                        label={`Tugasan 9`}
                                    />
                                    <Form.Check // prettier-ignore
                                        type={type}
                                        id={`default-${type}`}
                                        className='mb-2'
                                        label={`Tugasan 10`}
                                    />
                                    <Form.Check // prettier-ignore
                                        type={type}
                                        id={`default-${type}`}
                                        className='mb-2'
                                        label={`Tugasan 11`}
                                    />


                                </div>
                            ))}
                        </Form>
                    </div>

                </div> */}

            </div>

            <div className="w3-sidebar w3-bar-block w3-card w3-animate-right sidebarqr d-none" style={sidebarStyle2} id="mySidebar2">


                {/* <button className="w3-bar-item w3-button w3-large" onClick={closeSidebar}>Close &times;</button> */}
                <div className='p-0 side_body'>
                    {/* <label className='bold_poppins text-black'>History</label> */}
                    <div className='sidebar_scroll2 mt-3'>
                        {/* Use the isActive function to add the 'active' class conditionally */}
                        <div className={isActive('/dashboard') ? 'mb-2 menu_active' : 'mb-2'}>
                            <a className="text-white regular_poppins text-decoration-none" href="/dashboard">Dashboard</a>
                        </div>
                        {canManageZone && <div className={isActive('/zone') ? 'mb-2 menu_active' : 'mb-2'}>
                            <a className="text-white regular_poppins text-decoration-none" href="/zone">Zon</a>
                        </div>}
                        {canManageGarden && <div className={isActive('/taman') ? 'mb-2 menu_active' : 'mb-2'}>
                            <a className="text-white regular_poppins text-decoration-none" href="/taman">Taman</a>
                        </div>}
                        {canManageRole && <div className={isActive('/role') ? 'mb-2 menu_active' : 'mb-2'}>
                            <a className="text-white regular_poppins text-decoration-none" href="/role">Peranan</a>
                        </div>}
                        {canManageUser && <div className={isActive('/user') ? 'mb-2 menu_active' : 'mb-2'}>
                            <a className="text-white regular_poppins text-decoration-none" href="/user">Pengguna</a>
                        </div>}
                    </div>


                </div>




                {/* <div className='d-flex logout_mobile regular_poppins text-white p-3'>

                    Log Out
                    <button className='btn_logout_resp' onClick={handleLogout}>
                        <FontAwesomeIcon className='ml-2 svg' icon={faSignOut} />
                    </button>
                </div> */}


            </div>

        </div>

    );
}

export default Sidebar;
