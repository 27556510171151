import React, { useEffect, useState } from 'react';
import { Document, Page, Text, PDFViewer, View, StyleSheet, Image  } from '@react-pdf/renderer';
import { API_BASE_URL, IMAGE_BASE_URL } from '../constants/apiConfig';

const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: '#4BB790',
    padding: 20,
    zIndex: 1,
  },
  section: {
    margin: 30,
    padding: 30,
    flexGrow: 1,
    border: '1 solid #fff',
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
  },
  content: {
    fontSize: 16,
  },
  header: {
    paddingTop: 30,
    paddingBottom: 10,
    alignItems: 'center',
  },
  zoneText: {
    fontSize: 30,
    color: '#F03B22',
    fontWeight: 900, 
  },
  centeredText: {
    fontSize: 25,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  logoContainer: {
    position: 'absolute',
    top: -100,
    left: 10,
    zIndex: 2,
  },
  mardiLogo: {
    width: 130,
    height: 130,
  },
});

const MyDocument = ({ tamanId, tamanName, zoneName, qrCodeImageData }) => {
  return (
    <Document>
      <Page>
        <View style={styles.header}>
          <Text style={styles.zoneText}>{`${zoneName}`}</Text>
        </View>
        <View style={styles.page}>
            
            <View style={styles.section}>
            <View style={styles.logoContainer}>
              <Image src='/images/mardi_logo.png' style={styles.mardiLogo} />
            </View>
              <Text style={styles.centeredText}>{`${tamanName}`}</Text>
              {qrCodeImageData && <Image src={qrCodeImageData} />}
            </View>
        </View>
      </Page>
    </Document>
  );
};

const DocumentView = ({ tamanId, tamanName, zoneName, qrCodeImageData }) => (
  <PDFViewer style={{ width: '100%', height: '100vh' }}>
    <MyDocument tamanId={tamanId} tamanName={tamanName} zoneName={zoneName}/>
  </PDFViewer>
);

export default MyDocument;
