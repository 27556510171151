import React, { useEffect, useState } from 'react';
import LayoutPage from '../layouts/base4';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useNavigate, useParams } from 'react-router-dom';
import { API_BASE_URL, IMAGE_BASE_URL, AUDIO_BASE_URL, MARDI_DEV_URL } from '../constants/apiConfig';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function PublicViewPlantPage() {

    const navigate = useNavigate();

    const { plantId } = useParams();
    const [tamanData, setTamanData] = useState([]);
    const [itemData, setItemData] = useState([]);
    const [itemImages, setItemImages] = useState([]);
    const [zonData, setZonData] = useState([]);

    useEffect(() => {
        const fetchZoneData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}plant/edit/${plantId}`);
                if (response.ok) {
                    const data = await response.json();
                    setItemData(data.plant);
                    setTamanData(data.garden);
                    setZonData(data.zone);
                    if (data.plant && data.plant.plant_images) {
                        setItemImages(data.plant.plant_images);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchZoneData();
    }, [plantId]);

    var nama_item = itemData.nama;
    var nama_saintifik = itemData.nama_saintifik;

    function formatSaintifikNama(string) {
        return string.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    return (
        <LayoutPage>
            <div className='m-3'>
                <div className='container py-2'>
                    <div className='p-0'>
                        <div className='row'>
                            <div className='col-lg-3 col-5'>
                                {itemImages && itemImages.length > 0 && (
                                    <div className='preview-container'>
                                        <img
                                            src={`${IMAGE_BASE_URL}${itemImages[0]?.name}`}
                                            className="img_head mb-2"
                                            alt={`Preview-0`}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className='col-lg-9 col-7'>
                                <div className='desktop-title'>
                                    <h3 className='nama_view'>{nama_item ? nama_item.toUpperCase() : ''} - {nama_saintifik ? formatSaintifikNama(nama_saintifik) : ''}</h3>
                                </div>
                                <div className='mobile-title'>
                                    <h3 className='nama_view'>{nama_item ? nama_item.toUpperCase() : ''}</h3>
                                    <h3 className='nama_saintifik'>{nama_saintifik ? formatSaintifikNama(nama_saintifik) : ''}</h3>
                                </div>

                                <div className='py-1'><span className='title-item'>Zon: </span><span className='title-details'>{tamanData.zone_name}</span></div>
                                <div className='pt-1'><span className='title-item'>Taman: </span></div>
                                <div className='pb-1'><span className='title-details'>{tamanData.name}</span></div>
                                <div className='pt-1'><span className='title-item'>Jenis: </span></div>
                                <div className='pb-1'><span className='title-details'>{itemData.jenis}</span></div>
                                <div className='pt-1'><span className='title-item'>Spesies: </span></div>
                                <div><span className='title-details'>{itemData.spesis}</span></div>
                            </div>
                        </div>

                        <div>
                            <label className='label_view regular_poppins title-item'>Penerangan:</label>
                            <p className='regular_poppins text-justify'>{itemData.penerangan}</p>
                        </div>
                        
                        <div className='row mb-3'>
                            <div className='col-lg-9 col-7'>
                                <div className='ciri-item'>
                                    <label className='label_view regular_poppins title-item'>Ciri-ciri:</label>
                                </div>
                                <div style={{ minHeight: '100%', height: '100%', overflow: 'auto' }}>
                                    <p className='regular_poppins text-justify'>{itemData.ciri_ciri}</p>
                                </div>
                            </div>
                            <div className='col-lg-3 col-5 text-center'>
                                <Slider
                                    dots={true}
                                    arrows={true}
                                    prevArrow={<button type="button" className="slick-prev">Previous</button>}
                                    nextArrow={<button type="button" className="slick-next">Next</button>}
                                >
                                    {itemImages.map((image, index) => (
                                        <div key={index} className='preview-container '>
                                            <img
                                                src={`${IMAGE_BASE_URL}${image.name}`}
                                                className="img-fluid img_view2 mb-2"
                                                alt={`Preview-${index}`}
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>

                        <div>
                            <label className='label_view regular_poppins title-item'>Penjagaan Tumbuhan:</label>
                            <p className='regular_poppins text-justify'>{itemData.penjagaan}</p>
                        </div>
                        <div>
                            <label className='label_view regular_poppins title-item'>URL:</label>
                            <p className='regular_poppins text-justify' style={{ wordWrap: 'break-word' }}>{itemData.url}</p>
                        </div>

                        {/* Display existing audio file */}
                        {itemData.audio && (
                            <div>
                                <label className='label_view regular_poppins title-item'>Audio:</label>
                                <div>
                                    <audio controls>
                                        <source src={`${AUDIO_BASE_URL}${itemData.audio}`} type="audio/mpeg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                </div>
                            </div>
                        )}

                        <div className=' mt-5 mb-5 text-center'>
                            <button className='btn btnKembali mr-3' onClick={() => navigate(`/listOfPlant/${tamanData.id}`)}>Kembali</button>
                        </div>
                    </div>
                </div>

            </div>
        </LayoutPage>
    )
}

export default PublicViewPlantPage