import LayoutPage from "../layouts/base3"
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL, IMAGE_BASE_URL, MARDI_DEV_URL } from "../constants/apiConfig";
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import SuccessModal from "../components/success-modal";
import QRCode from "qrcode.react";
import QRCodeNew from "qrcode";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "./DocumentView";
import { setTamanId } from "../services/auth";
import { useAuth } from "../services/AuthProvider";

function MyVerticallyCenteredModal(props) {

    const tamanId = props.id;
    const qrCodeImageData = props.qrCodeImageData;
    const [tamanData, setTamanData] = useState([]);
    const url = `${MARDI_DEV_URL}listOfPlant/${tamanData.id}`;

    useEffect(() => {
        const fetchTamanData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}plant/index/${tamanId}`);
                if (response.ok) {
                    const data = await response.json();
                    setTamanData(data.garden)
                }
            } catch (error) {
                console.error('Error fetching zone data:', error);
            }
        };

        fetchTamanData();
    }, [tamanId]);

    const handleGenerateQRCode = async () => {
        try {
            // Generate QR code as an HTMLCanvasElement
            const canvas = document.createElement('canvas');
            QRCodeNew.toCanvas(canvas, url);

            // Convert canvas to data URL
            const qrCodeDataURL = canvas.toDataURL('image/png');

            localStorage.setItem('redirectUrl', url);

            await saveQRCodeToServer(qrCodeDataURL);
        } catch (error) {
            console.error('Error generating or saving QR code:', error);
        }
    };

    const saveQRCodeToServer = async (dataURL) => {
        try {
            await fetch(`${API_BASE_URL}garden/storeQRCode/${tamanId}`, {
                method: 'POST',
                body: JSON.stringify({ dataURL }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            console.log('QR code image sent to server:' + dataURL);
        } catch (error) {
            throw new Error('Error sending QR code image to server');
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter modal_qr"
            centered
        >
            <div className="mb-3">
                <Modal.Header closeButton>
                </Modal.Header>
                <h3 className="bold_poppins text-danger mt-3">{tamanData.zone_name}</h3></div>

            <Modal.Body className="bg_green p-5 mb-3">

                <div className="bg-white p-0">
                    <div className="text-left">
                        <img
                            src="/images/mardi_logo.png"
                            className="img-fluid logo_inQR"
                            alt="..."
                        />
                    </div>

                    <div >
                        <h4 className="bold_poppins">{tamanData.name}</h4>
                        <QRCode value={url} size="180" className="my-3" />
                    </div>
                    <div className='form_btn mt-3 d-flex flex-wrap justify-content-around'>
                        <PDFDownloadLink className=""
                            document={<MyDocument tamanId={tamanData.id} tamanName={tamanData.name} zoneName={tamanData.zone_name} qrCodeImageData={qrCodeImageData} />}
                            fileName={`${tamanData.name}.pdf`}
                            onClick={handleGenerateQRCode}
                        >
                            {({ loading }) => (
                                <button className='printQR_btn'>
                                    {loading ? 'Loading document...' : 'Cetak PDF'}
                                </button>
                            )}
                        </PDFDownloadLink>
                        {/* <button className='btn formBatalBtn mb-2' onClick={() => { window.open(`${MARDI_DEV_URL}listOfPlant/${tamanData.id}`) }}>View</button> */}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}



function TamanListingPage() {

    const navigate = useNavigate()

    const itemsPerPage = 10;

    const { user, hasPermission } = useAuth();

    const canManageGarden = hasPermission('manage-garden');
    const canCreateGarden = hasPermission('create-garden');
    const canEditGarden = hasPermission('edit-garden');
    const canDeleteGarden = hasPermission('delete-garden');

    const [selectedZone, setSelectedZone] = useState('');
    const [tamanData, setTamanData] = useState([]);
    const [zoneData, setZoneData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showSuccessDeleteModal, setshowSuccessDeleteModal] = useState(false);
    const handleModalClose = () => setShowModal(false);
    const handleTamanModal = () => setShowModal(true);
    const [namaTaman, setNamaTaman] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const handleEditModalClose = () => setShowEditModal(false);

    const [modalShow, setModalShow] = useState(false);
    const [tamanIndex, setTamanIndex] = useState(null);

    const [qrCodeImageData, setQRCodeImageData] = useState('');
    const [searchQuery, setSearchQuery] = useState('');

    const [formData, setFormData] = useState({
        namaTaman: '',
        selectedZone: '',
        image: '',
    });

    const handleGenerateQRCode = async (tamanId) => {
        try {
            const canvas = document.createElement('canvas');
            const url = `${MARDI_DEV_URL}listOfPlant/${tamanId}`;
            QRCodeNew.toCanvas(canvas, url);
            const qrCodeDataURL = canvas.toDataURL('image/png');

            setQRCodeImageData(qrCodeDataURL);
        } catch (error) {
            console.error('Error generating or saving QR code:', error);
        }
    };

    const openQRModalWithId = (id) => {
        setTamanIndex(id);
        setModalShow(true);
        handleGenerateQRCode(id);
    };

    const handleQRModalClose = () => {
        setModalShow(false);
        setTamanIndex(null);
    };

    useEffect(() => {
        const fetchZoneData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}garden/getGardenData`);
                if (response.ok) {
                    const data = await response.json();
                    setZoneData(data.zone);
                    if (data.zone.length > 0) {
                        setSelectedZone(data.zone[0].id);
                    }
                }
            } catch (error) {
                console.error('Error fetching zone data:', error);
            }
        };

        fetchZoneData();
    }, []);

    useEffect(() => {
        if (selectedZone !== '') {
            fetch(`${API_BASE_URL}garden/index/${selectedZone}`)
                .then((response) => response.json())
                .then((data) => {
                    setTamanData(data);
                })
                .catch((error) => {
                    console.error('Error fetching garden data:', error);
                });
        }
    }, [selectedZone]);

    const handleZoneChange = (e) => {
        setSelectedZone(e.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = tamanData.filter((taman) =>
        taman && taman.name && taman.name.toLowerCase().includes(searchQuery.toLowerCase())
    );


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleImageChange = (e) => {
        setFormData({
            ...formData,
            image: e.target.files[0],
        })
    };


    const handleSubmitTaman = async (e) => {
        e.preventDefault();

        try {
            const formDataObject = new FormData();

            formDataObject.append('namaTaman', formData.namaTaman);
            formDataObject.append('selectedZone', selectedZone);
            if (formData.image) {
                formDataObject.append('image', formData.image);
            }      

            const response = await fetch(`${API_BASE_URL}garden/store`, {
                method: 'POST',
                body: formDataObject,
            });

            if (response.status == 200) {
                setShowModal(false);
                setShowSuccessModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    }

    const [editedTamanName, setEditedTamanName] = useState('');
    const [editTamanId, setEditTamanId] = useState(null);
    const [editTamanData, setEditTamanData] = useState([]);
    const [editFormData, setEditFormData] = useState([]);

    // Function to fetch taman data by ID
    const fetchTamanById = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}garden/edit/${id}`);
            if (response.ok) {
                const data = await response.json();
                
                setEditTamanData((prevTamanData) => ({
                    ...prevTamanData,
                    ...data,
                }));

                setEditFormData((prevTamanData) => ({
                    ...prevTamanData,
                    namaTaman: data.name || '',
                    selectedZone: data.zone_id || '',
                    image: data.image || '',
                }));
            }
        } catch (error) {
            console.error('Error fetching garden:', error);
        }
    };

    useEffect(() => {
        if (editTamanId !== null) {
            fetchTamanById(editTamanId);
        }
    }, [editTamanId]);

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditFormData({
            ...editFormData,
            [name]: value,
        });
    };

    const handleEditImageChange = (e) => {
        setEditFormData({
            ...editFormData,
            image: e.target.files[0],
        })
    };

    const handleEditTamanModal = (tamanId) => {
        setEditTamanId(tamanId);
        setShowEditModal(true);
    };

    const handleSubmitEditTaman = async (e) => {
        e.preventDefault();

        try {
            const formDataObject = new FormData();

            formDataObject.append('namaTaman', editFormData.namaTaman);
            formDataObject.append('selectedZone', editFormData.selectedZone);

            if (editFormData.image) {
                formDataObject.append('image', editFormData.image);
            }

            const response = await fetch(`${API_BASE_URL}garden/update/${editTamanId}`, {
                method: 'POST',
                body: formDataObject,
            });

            if (response.ok) {
                setShowEditModal(false);
                setShowSuccessModal(true);
            }
        } catch (error) {
            // Handle fetch errors
            console.error('Error updating taman:', error);
        }
    }

    const handleDeleteTaman = async (index) => {
        try {
            const response = await fetch(`${API_BASE_URL}garden/remove/${index}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status == 200) {
                setShowDeleteModal(false);
                setshowSuccessDeleteModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    };

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteIndex(null);
    };

    const handleConfirmDelete = (id) => {
        setDeleteIndex(id);
        setShowDeleteModal(true);
    };

    const closeSuccessModal = () => {
        setShowSuccessModal(false);
        window.location.reload();
    };

    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const renderIndex = (index) => {
        return (currentPage - 1) * itemsPerPage + index + 1;
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    return (
        <LayoutPage>
            <div className="d-flex flex-wrap listTopBar top_bar p-3 bold_poppins justify-content-between">
                <div>
                    <h4 className="mt-3 pg_title">
                        SENARAI TAMAN AGROTEKNOLOGI
                    </h4>
                </div>

                <div className="d-flex flex-wrap">
                    <div className="mr-3">
                        <Form.Select className="zone_selector"
                            onChange={handleZoneChange}
                            value={selectedZone}
                        >
                            {zoneData.map((zone) => (
                                <option key={zone.id} value={zone.id}>
                                    {zone.name}
                                </option>
                            ))}
                        </Form.Select>
                    </div>

                    <div className="">
                        <Form.Control
                            placeholder="Cari..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            aria-label=""
                            aria-describedby="basic-addon1"
                            style={{ width: "200px" }}
                        />
                    </div>
                </div>
            </div>
            <div className="m-4">
                {canCreateGarden && <div className="addZone d-flex justify-content-center mb-4" onClick={handleTamanModal}>
                    <div className="icon">
                        <FontAwesomeIcon className="icon" icon={faPlus} />
                    </div>
                    <div className=" text">
                        Taman
                    </div>
                </div>}
                <div>
                    {currentItems.map((taman, index) => (
                        <div key={index} className="listBar p-3 my-3" onClick={() => navigate(`/plant/${taman.id}`)} style={{ cursor: 'pointer' }}>
                            <div className="d-flex justify-content-between mx-4 nowrap">
                                <div className="regular_poppins">
                                    {renderIndex(index)}. {taman.name}
                                </div>
                                <div className="regular_poppins">
                                    {canManageGarden && <FontAwesomeIcon icon={faQrcode} className="icon qr-code-icon" onClick={(e) => { e.stopPropagation(); setTamanId(taman.id); openQRModalWithId(taman.id) }} />}
                                    {canEditGarden && <FontAwesomeIcon icon={faPencil} className="icon edit-icon" onClick={(e) => { e.stopPropagation(); handleEditTamanModal(taman.id); }} />}
                                    {canDeleteGarden && <FontAwesomeIcon icon={faTrash} className="icon delete-icon" onClick={(e) => { e.stopPropagation(); handleConfirmDelete(taman.id); }} />}
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className='pagination'>
                        <button onClick={handlePrevPage} disabled={currentPage === 1}>
                            Prev
                        </button>
                        <button onClick={handleNextPage} disabled={indexOfLastItem >= tamanData.length}>
                            Next
                        </button>
                    </div>
                </div>

            </div>

            {/* Modal Create Taman */}
            <Modal show={showModal} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Tambah Taman</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Your form content */}
                    <Form onSubmit={handleSubmitTaman}>
                        <Form.Group controlId="formZoneSelect">
                            <Form.Label className="label-left">Zon</Form.Label>
                            <Form.Select
                                value={selectedZone}
                                onChange={handleZoneChange}
                                aria-label="Pilih Zon"
                                disabled={selectedZone !== null}
                            >
                                {zoneData.map((zone) => (
                                    <option key={zone.id} value={zone.id}>
                                        {zone.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="formTamanName" className="mt-3">
                            <Form.Label className="label-left">Nama Taman</Form.Label>
                            <Form.Control value={formData.namaTaman} onChange={handleInputChange} name="namaTaman" type="text" placeholder="Masukkan nama taman" required />
                        </Form.Group>
                        <Form.Group controlId="">
                            <Form.Label className="label-left mt-3">Gambar</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={handleImageChange}
                                accept="image/*"
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4">
                            Simpan
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal Update Taman */}
            <Modal show={showEditModal} onHide={handleEditModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Taman</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitEditTaman}>
                        {editTamanData.image && (
                            <div className="rounded-image-preview">
                                <a href={`${IMAGE_BASE_URL}gardens/${editTamanData.image}`} target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={`${IMAGE_BASE_URL}gardens/${editTamanData.image}`}
                                        alt="Preview"
                                        className='preview-profile mb-1'
                                    />
                                </a>
                                <p style={{ fontSize: '12px', color: 'blue' }}>Klik gambar untuk paparan penuh</p>
                            </div>
                        )}
                        <Form.Group controlId="formZoneSelect">
                            <Form.Label className="label-left mt-3">Zon</Form.Label>
                            <Form.Select
                                value={selectedZone}
                                onChange={handleEditInputChange}
                                aria-label="Pilih Zon"
                                disabled={selectedZone !== null}
                            >
                                {zoneData.map((zone) => (
                                    <option key={zone.id} value={zone.id}>
                                        {zone.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="formTamanName" className="mt-3">
                            <Form.Label className="label-left">Nama Taman</Form.Label>
                            <Form.Control
                                value={editFormData.namaTaman}
                                onChange={handleEditInputChange}
                                name="namaTaman"
                                type="text"
                                placeholder="Masukkan nama taman"
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="">
                            <Form.Label className="label-left mt-3">Gambar</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={handleEditImageChange}
                                accept="image/*"
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4">
                            Kemaskini
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Delete Modal */}
            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Pengesahan Padam</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Adakah anda pasti ingin memadam taman ini?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseDeleteModal}>
                        Batal
                    </Button>
                    <Button className="delete-button" onClick={() => handleDeleteTaman(deleteIndex)}>
                        Padam
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal/Popup for Success Message */}
            {showSuccessModal && (
                <SuccessModal
                    show={showSuccessModal}
                    onHide={closeSuccessModal}
                    title="Tindakan Berjaya"
                    modalBody={<p>Data telah berjaya disimpan!</p>}
                    buttonText="Tutup"
                />
            )}

            {/* Modal/Popup for Confirmation Delete */}
            {showSuccessDeleteModal && (
                <SuccessModal
                    show={showSuccessDeleteModal}
                    onHide={closeSuccessModal}
                    title="Tindakan Berjaya"
                    modalBody={<p>Data telah berjaya dipadam!</p>}
                    buttonText="Tutup"
                />
            )}

            {modalShow && (
                <MyVerticallyCenteredModal
                    show={modalShow}
                    id={tamanIndex}
                    onHide={handleQRModalClose}
                    qrCodeImageData={qrCodeImageData}
                />
            )}
        </LayoutPage>
    )
}

export default TamanListingPage