// import LayoutPage from './Base';
import { useEffect, useState } from 'react';
import LayoutPage from '../layouts/base3';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { API_BASE_URL } from '../constants/apiConfig';
import { throttle } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faA, faAngleDown, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import SuccessModal from '../components/success-modal';
import { useNavigate, useParams } from 'react-router-dom';

function BorangTamanPage() {

    var title_page = 'borang taman agroteknologi';

    const navigate = useNavigate();

    const { tamanId } = useParams();
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [namaTamanOptions, setNamaTamanOptions] = useState([]);
    const [zonOptions, setZonOptions] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedAudioFile, setSelectedAudioFile] = useState(null);
    const [formData, setFormData] = useState({
        namaTaman: '',
        zon: '',
        namaItem: '',
        namaSaintifik: '',
        penerangan: '',
        ciriCiri: '',
        jenis: '',
        spesies: '',
        penjagaan: '',
        url: '',
        galeri: [],
    });

    const fetchGardenData = () => {
        fetch(`${API_BASE_URL}garden/getGardenData`)
            .then((response) => response.json())
            .then((data) => {
                setNamaTamanOptions(data.taman);
                setZonOptions(data.zone);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        const throttledFetchData = throttle(fetchGardenData, 3000);
        throttledFetchData(); // Initial fetch

        return () => {
            // Cleanup: Ensure to clear the throttled function to avoid memory leaks
            throttledFetchData.cancel();
        };
    }, []);

    useEffect(() => {
        if (tamanId) {
            const selectedTaman = namaTamanOptions.find((taman) => taman.id === parseInt(tamanId));

            if (selectedTaman) {
                const selectedZone = zonOptions.find((zone) => zone.id === parseInt(selectedTaman.zone_id));
                setFormData({
                    ...formData,
                    namaTaman: tamanId,
                    zon: selectedZone ? selectedZone.name : '',
                });
            }
        }
    }, [formData.namaTaman, namaTamanOptions, zonOptions]);

    const handleTamanChange = (selectedTamanId) => {
        const selectedTaman = namaTamanOptions.find((taman) => taman.id === parseInt(selectedTamanId));

        if (selectedTaman) {
            const selectedZone = zonOptions.find((zone) => zone.id === parseInt(selectedTaman.zone_id));
            setFormData({
                ...formData,
                namaTaman: selectedTamanId,
                zon: selectedZone.name || '',
            });
        } else {
            setFormData({
                ...formData,
                namaTaman: '',
                zon: '',
            });
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);

        const images = files.map((file) => ({
            file,
            preview: URL.createObjectURL(file),
        }));

        setSelectedImages([...selectedImages, ...images]);
        setFormData({
            ...formData,
            galeri: [...formData.galeri, ...files],
        });
    }

    const handleRemoveImage = (indexToRemove) => {
        const updatedImages = selectedImages.filter(
            (_, index) => index !== indexToRemove
        );
        setSelectedImages(updatedImages);

        const updatedFiles = formData.galeri.filter(
            (_, index) => index !== indexToRemove
        );
        setFormData({
            ...formData,
            galeri: updatedFiles,
        });
    }

    const handleAudioChange = (event) => {
        const files = event.target.files[0];
        setSelectedAudioFile(files);
    };

    const handleTambahTumbuhan = async (e) => {
        e.preventDefault();

        try {
            const formDataWithFiles = new FormData();

            Object.entries(formData).forEach(([key, value]) => {
                if (key !== 'galeri') {
                    formDataWithFiles.append(key, value);
                }
            });

            formData.galeri.forEach((file, index) => {
                formDataWithFiles.append(`galeri[${index}]`, file);
            });

            if (selectedAudioFile) {
                formDataWithFiles.append('audioFile', selectedAudioFile);
            }

            const response = await fetch(`${API_BASE_URL}plant/store`, {
                method: 'POST',
                body: formDataWithFiles,
            });

            const data = await response.json();
            console.log(response.status)

            if (response.status == 200) {
                setShowSuccessModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    };

    const closeSuccessModal = () => {
        setShowSuccessModal(false);
        navigate(`/plant/${tamanId}`);

    };

    return (
        <LayoutPage>
            <div class="m-3">
                <form onSubmit={handleTambahTumbuhan}>
                    <h3 className='bold_poppins mt-3 mb-5'>{title_page.toUpperCase()}</h3>
                    <div class="row mb-3 mt-1">
                        <div class="col-md-2 ">
                            <Form.Label className="label_control" htmlFor="basic-url">Nama Taman:</Form.Label>
                        </div>
                        <div class="col-md-7">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    as="select"
                                    id="namaTaman"
                                    name="namaTaman"
                                    value={formData.namaTaman || tamanId}
                                    onChange={(e) => handleTamanChange(e.target.value)}
                                    aria-describedby="basic-addon3"
                                    disabled
                                >
                                    <option value="">Pilih..</option>
                                    {namaTamanOptions.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </InputGroup>
                        </div>
                        <div class="col-md-1 ">
                            <Form.Label className="label_control" htmlFor="basic-url">Zon:</Form.Label>
                        </div>
                        <div class="col-md-2">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    value={formData.zon}
                                    onChange={handleInputChange}
                                    id="zon"
                                    name="zon"
                                    aria-describedby="basic-addon3"
                                    disabled
                                />
                            </InputGroup>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-2 ">
                            <Form.Label className="label_control" htmlFor="basic-url">Nama Item:</Form.Label>
                        </div>
                        <div class="col-md-5">
                            <InputGroup className="mb-3">
                                <Form.Control value={formData.namaItem} onChange={handleInputChange} name='namaItem' id="basic-url" aria-describedby="basic-addon3" required />
                            </InputGroup>
                        </div>
                        <div class="col-md-2 ">
                            <Form.Label className="label_control" htmlFor="basic-url">Nama Saintifik:</Form.Label>
                        </div>
                        <div class="col-md-3">
                            <InputGroup className="mb-3">
                                <Form.Control value={formData.namaSaintifik} onChange={handleInputChange} name='namaSaintifik' id="basic-url" aria-describedby="basic-addon3" />
                            </InputGroup>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-2 ">
                            <Form.Label className="label_control" htmlFor="basic-url">Penerangan:</Form.Label>
                        </div>
                        <div class="col-md-10">
                            <InputGroup className="mb-3">
                                <Form.Control value={formData.penerangan} onChange={handleInputChange} as="textarea" name='penerangan' id="basic-url" aria-describedby="basic-addon3" required />
                            </InputGroup>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-2 ">
                            <Form.Label className="label_control" htmlFor="basic-url">Galeri:</Form.Label>
                        </div>
                        <div class="col-md-10">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    type="file"
                                    multiple
                                    onChange={handleImageChange}
                                    name="galeri"
                                    id='galeri'
                                    aria-describedby='basic-addon3'
                                />
                            </InputGroup>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-2"></div>
                        <div class="col-md-6 image-container">
                            {selectedImages.map((image, index) => (
                                <div key={index} className='preview-container'>
                                    <img
                                        src={image.preview}
                                        alt={`Preview-${index}`}
                                        className='preview-image'
                                    />
                                    <div className='text-center'><button
                                        onClick={() => handleRemoveImage(index)}
                                        className="remove-button"
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button></div>

                                </div>
                            ))}
                        </div>
                        <div class="col-md-4">
                            <div class="row mb-3 justify-content-end">
                                <div class="col-md-4 text-right text_left">
                                    <Form.Label className="label_control" htmlFor="basic-url">Jenis:</Form.Label>
                                </div>
                                <div class="col-md-7">
                                    <InputGroup className="mb-3">
                                        <Form.Control value={formData.jenis} onChange={handleInputChange} name='jenis' id="basic-url" aria-describedby="basic-addon3" required />
                                    </InputGroup>
                                </div>
                            </div>
                            <div class="row mb-3 justify-content-end">
                                <div class="col-md-4 text-right text_left">
                                    <Form.Label className="label_control" htmlFor="basic-url">Spesies:</Form.Label>
                                </div>
                                <div class="col-md-7">
                                    <InputGroup className="mb-3">
                                        <Form.Control value={formData.spesis} onChange={handleInputChange} name='spesies' id="basic-url" aria-describedby="basic-addon3" required />
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-md-2 ">
                            <Form.Label className="label_control" htmlFor="basic-url">Ciri-ciri:</Form.Label>
                        </div>
                        <div class="col-md-10">
                            <InputGroup className="mb-3">
                                <Form.Control value={formData.ciriCiri} onChange={handleInputChange} name='ciriCiri' as="textarea" id="basic-url" aria-describedby="basic-addon3" required />
                            </InputGroup>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-2 ">
                            <Form.Label className="label_control" htmlFor="basic-url">Penjagaan Tumbuhan:</Form.Label>
                        </div>
                        <div class="col-md-10">
                            <InputGroup className="mb-3">
                                <Form.Control value={formData.penjagaan} onChange={handleInputChange} name='penjagaan' as="textarea" id="basic-url" aria-describedby="basic-addon3" required />
                            </InputGroup>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-2 ">
                            <Form.Label className="label_control" htmlFor="basic-url">URL:</Form.Label>
                        </div>
                        <div class="col-md-10">
                            <InputGroup className="mb-3">
                                <Form.Control value={formData.url} onChange={handleInputChange} name='url' id="basic-url" aria-describedby="basic-addon3" />
                            </InputGroup>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-2 ">
                            <Form.Label className="label_control" htmlFor="basic-url">Audio:</Form.Label>
                        </div>
                        <div className="col-md-10">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    type="file"
                                    accept="audio/*"
                                    multiple
                                    onChange={handleAudioChange}
                                    name="audioFiles"
                                    id="audioFiles"
                                    aria-describedby="basic-addon3"
                                />
                            </InputGroup>
                        </div>
                    </div>

                    <div className=' mt-5 mb-5 text-center'>
                        <button type='submit' className='btn formSimpanBtn'>Simpan</button>
                        <button className='btn formBatalBtn' onClick={() => navigate(`/taman`)}>Batal</button>
                    </div>
                </form>

                {/* Modal/Popup for Success Message */}
                {showSuccessModal && (
                    <SuccessModal
                        show={showSuccessModal}
                        onHide={closeSuccessModal}
                        title="Tindakan Berjaya"
                        modalBody={<p>Maklumat telah berjaya disimpan!</p>}
                        buttonText="Tutup"
                    />
                )}
            </div>
        </LayoutPage>
    )
}

export default BorangTamanPage