import { useEffect, useState } from 'react';
import LayoutPage from '../layouts/base3';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useNavigate, useParams } from 'react-router-dom';
import { API_BASE_URL, IMAGE_BASE_URL, AUDIO_BASE_URL } from '../constants/apiConfig';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


function ViewTaman() {

    const navigate = useNavigate();

    const { plantId } = useParams();
    const [tamanData, setTamanData] = useState([]);
    const [itemData, setItemData] = useState([]);
    const [itemImages, setItemImages] = useState([]);
    const [zonData, setZonData] = useState([]);

    useEffect(() => {
        const fetchZoneData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}plant/edit/${plantId}`);
                if (response.ok) {
                    const data = await response.json();
                    setItemData(data.plant);
                    setTamanData(data.garden);
                    setZonData(data.zone);
                    if (data.plant && data.plant.plant_images) {
                        setItemImages(data.plant.plant_images);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchZoneData();
    }, [plantId]);

    var nama_item = itemData.nama;
    var nama_saintifik = itemData.nama_saintifik;

    function formatSaintifikNama(string) {
        return string.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    return (
        <div>
            <LayoutPage>
                <div className='m-3'>
                    <h3 className='mt-3 mb-5'>{nama_item ? nama_item.toUpperCase() : ''} - {nama_saintifik ? formatSaintifikNama(nama_saintifik) : ''}</h3>
                    <div className='container'>
                        <div className="row mb-3 mt-1">
                            <div className="col-md-1 p-0">
                                <Form.Label className="label_control" htmlFor="basic-url">Taman:</Form.Label>
                            </div>
                            <div className="col-md-8">
                                <InputGroup className="mb-3">
                                    <Form.Control id="basic-url" aria-describedby="basic-addon3" value={tamanData.name} disabled />
                                </InputGroup>
                            </div>
                            <div className="col-md-1">
                                <Form.Label className="label_control" htmlFor="basic-url">Zon:</Form.Label>
                            </div>
                            <div className="col-md-2">
                                <InputGroup className="mb-3">
                                    <Form.Control id="basic-url" aria-describedby="basic-addon3" value={tamanData.zone_name} disabled />
                                </InputGroup>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-2  text-center slider_img'>
                                <Slider
                                    dots={true}
                                    arrows={true}
                                    prevArrow={<button type="button" className="slick-prev">Previous</button>}
                                    nextArrow={<button type="button" className="slick-next">Next</button>}
                                >
                                    {itemImages.map((image, index) => (
                                        <div key={index} className='preview-container '>
                                            <img
                                                src={`${IMAGE_BASE_URL}${image.name}`}
                                                className="img-fluid img_view2 mb-2"
                                                alt={`Preview-${index}`}
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            </div>


                            <div className='col-lg-10 view_details'>
                                <div>
                                    <label className='label_view regular_poppins'>Jenis:</label>
                                    <p className='data_view regular_poppins'>{itemData.jenis}</p>
                                </div>
                                <div>
                                    <label className='label_view regular_poppins'>Spesies:</label>
                                    <p className='regular_poppins'>{itemData.spesis}</p>
                                </div>
                                <div>
                                    <label className='label_view regular_poppins'>Penerangan:</label>
                                    <p className='regular_poppins text-justify'>{itemData.penerangan}</p>
                                </div>
                                <div>
                                    <label className='label_view regular_poppins'>Ciri-ciri:</label>
                                    <p className='regular_poppins text-justify'>{itemData.ciri_ciri}</p>
                                </div>
                                <div>
                                    <label className='label_view regular_poppins'>Penjagaan Tumbuhan:</label>
                                    <p className='regular_poppins text-justify'>{itemData.penjagaan}</p>
                                </div>
                                <div>
                                    <label className='label_view regular_poppins'>URL:</label>
                                    <p className='regular_poppins text-justify'>{itemData.url}</p>
                                </div>

                                {/* Display existing audio file */}
                                {itemData.audio && (
                                    <div>
                                        <label className='label_view regular_poppins'>Audio:</label>
                                        <div>
                                            <audio controls>
                                                <source src={`${AUDIO_BASE_URL}${itemData.audio}`} type="audio/mpeg" />
                                                Your browser does not support the audio element.
                                            </audio>
                                        </div>
                                    </div>
                                )}

                                <div className=' mt-5 mb-5 text-center'>
                                    <button className='btn formKembaliBtn mr-3' onClick={() => navigate(`/plant/${tamanData.id}`)}>Kembali</button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </LayoutPage>
        </div>
    )
}

export default ViewTaman