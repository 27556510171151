import LayoutPage from './layouts/base3';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import React, { useState } from 'react';

function QrGenerator() {


    const [content, setContent] = useState('');
    const [qrImageUrl, setQrImageUrl] = useState('');

    const generateRandomContent = () => {
        // Generate a random string or content here
        const randomContent = Math.random().toString(36).substring(7);
        setContent(randomContent);
    };

    const handleGenerateClick = () => {
        generateRandomContent();

        // Assuming you have a function htmlEncode that performs the HTML encoding
        const encodedContent = htmlEncode(content);

        const finalURL = `https://chart.googleapis.com/chart?cht=qr&chl=${encodedContent}&chs=250x250&chld=L|0`;

        // Update state variables to reflect changes
        setQrImageUrl(finalURL);
    };


    return (
        <LayoutPage>
            <div className='base_layout m-3'>

                <h4 className='mt-4 bold_poppins'> QR <img
                    src="/images/qrcode_icon.png"
                    className="img-fluid qr_icon2 w3-black"
                    alt="..."
                /> Generator</h4>

                <div class="row mb-3 mt-5">
                    <div class="col-md-1">
                        <Form.Label className="label_control" htmlFor="basic-url">URL:</Form.Label>
                    </div>
                    <div class="col-md-11">
                        <InputGroup className="mb-3">
                            <Form.Control value={content} type="text" id="basic-url" aria-describedby="basic-addon3" onChange={(e) => setContent(e.target.value)} />
                        </InputGroup>
                    </div>
                    <div className=' mt-3  text-right'>
                        <button className='btn qrGenBtn' onClick={handleGenerateClick}>GENERATE</button>
                    </div>
                </div>
                <div className='text-center contentqr'>
                    {qrImageUrl && <img src={qrImageUrl} alt="QR Code" />}
                    {qrImageUrl && (
                        <div className='form_btn mt-3'>
                            {/* <div id="userNameInput">...</div> */}
                            <button id="saveButton" className='saveQR_btn' href={qrImageUrl} target="_blank" rel="noopener noreferrer">
                                Save
                            </button>
                            <button id="printButton" className='printQR_btn' href={qrImageUrl} target="_blank" rel="noopener noreferrer">
                                Print
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </LayoutPage>

    )
}

// Example HTML encode function
function htmlEncode(text) {
    return text.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
}


export default QrGenerator