import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from './constants/apiConfig';

import { Form, InputGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { setAuthData } from './services/auth';

function LoginPage() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        // Check authentication status when the component mounts
        const TOKEN_KEY = 'auth_token';
        if (localStorage.getItem(TOKEN_KEY)) {
            // If authenticated, redirect to the dashboard
            navigate('/dashboard');
        }
    }, [navigate]);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_BASE_URL}login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (response.ok) {
                const { token, userId } = data;
                setAuthData(token, userId);
                navigate('/dashboard');
            } else {
                setError(data.message || 'Log masuk gagal.');
                setEmail('');
                setPassword('');
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
            setError('Ralat berlaku semasa log masuk.');
        }
    };

    return (
        <div>
            <div className="d-flex justify-content-center align-items-center vh-100">
                <div className="card mb-3 " >
                    <div className="row g-0 login_card">
                        <div className="col-md-6 login_leftCard">
                            <img
                                src="/images/login_img.jpg"
                                className="img-fluid rounded-start login_img"
                                alt="..."
                            />
                        </div>
                        <div className="col-md-6 login_rightCard">
                            <div className="card-body">
                                <fieldset className='mt-3'>
                                    <legend>Log Masuk</legend>
                                    <div className='text-center'>
                                        <img
                                            src="/images/mardi_logo.png"
                                            className="img-fluid rounded-start mardi_logo"
                                            alt="..."
                                        />
                                    </div>

                                    <div>
                                        <form onSubmit={handleLogin}>
                                            <p className='login_label mt-3'>Log Masuk Akaun</p>
                                            <InputGroup className="mb-3 login_input" >
                                                <InputGroup.Text className="login_input" id="basic-addon1">@</InputGroup.Text>
                                                <Form.Control
                                                    className='login_input'
                                                    placeholder="E-mel"
                                                    aria-label="E-mel"
                                                    aria-describedby="basic-addon1"
                                                    value={email}
                                                    onChange={handleEmailChange}
                                                />
                                            </InputGroup>

                                            <InputGroup className="mb-4 login_input">
                                                <InputGroup.Text className="login_input" id="basic-addon1"> <FontAwesomeIcon icon={faLock} /></InputGroup.Text>
                                                <Form.Control
                                                    className='login_input'
                                                    type="password"
                                                    placeholder="Kata Laluan"
                                                    aria-label="Kata Laluan"
                                                    aria-describedby="basic-addon2"
                                                    value={password}
                                                    onChange={handlePasswordChange}
                                                />
                                            </InputGroup>
                                            {error && (
                                                <div className="error-message mb-4" style={{ color: 'red' }}>
                                                    {error}
                                                </div>
                                            )}
                                            <div className='text-center mb-4'>
                                                <Button type="submit" className="col-md-12 login_btn " variant="danger">Log Masuk</Button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='text-center mb-0 mt-5'>
                                        <p className='copyright'>2023 - Hak Cipta Terpelihara</p>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPage