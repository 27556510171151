import React, { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import LoginPage from './pages/Login';
import BorangTamanPage from './pages/tumbuhan/Create';
import ViewTamanPage from './pages/tumbuhan/View';
import QrGeneratorPage from './pages/QrGenerator';
import DashboardPage from './pages/Dashboard';
import ZoneListingPage from './pages/zone/Index';
import ProtectedRoute from './pages/services/ProtectedRoute';
import TamanListingPage from './pages/taman/Index';
import PlantListingPage from './pages/tumbuhan/Index';
import EditBorangTamanPage from './pages/tumbuhan/Edit';
import PublicPlantListingPage from './pages/tumbuhan/TumbuhanList';
import PublicViewPlantPage from './pages/tumbuhan/TumbuhanDetails';
import PublicDashboard from './pages/PublicDashboard';
import ManageUserPage from './pages/users/Index';
import RoleListPage from './pages/role/Index';
import AuthProvider from './pages/services/AuthProvider';


function App() {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route 
        exact 
        path='/' 
        element={
          <AuthProvider>
            <ProtectedRoute />
          </AuthProvider>
        }
      >
        <Route exact path='/dashboard' element={<DashboardPage />} />
        <Route path="/addGardenForm/:tamanId" element={<BorangTamanPage />} />
        <Route path="/qrGenerator" element={<QrGeneratorPage />} />
        <Route path="/viewGardenDetails/:plantId" element={<ViewTamanPage />} />
        <Route path="/zone" element={<ZoneListingPage />} />
        <Route path="/taman" element={<TamanListingPage />} />
        <Route path="/plant/:tamanId" element={<PlantListingPage />} />
        <Route path="/editGardenForm/:plantId" element={<EditBorangTamanPage />} />
        <Route path="/user" element={<ManageUserPage />} />
        <Route path="/role" element={<RoleListPage />} />
      </Route>
      <Route path="/listOfPlant/:tamanId" element={<PublicPlantListingPage />} />
      <Route path="/plantDetails/:tamanId/:plantId" element={<PublicViewPlantPage />} />
      <Route exact path='/map/:tamanId' element={<PublicDashboard />} />
    </Routes>
  );
}

export default App;
