import { useEffect, useState } from "react";
import { API_BASE_URL, IMAGE_BASE_URL } from "../constants/apiConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Modal } from "react-bootstrap";
import SuccessModal from "../components/success-modal";
import LayoutPage from "../layouts/base3";
import { useAuth } from "../services/AuthProvider";


function ManageUserPage() {

    const itemsPerPage = 10;

    const { user, hasPermission } = useAuth();

    const canManageUser = hasPermission('manage-user');
    const canCreateUser = hasPermission('create-user');
    const canEditUser = hasPermission('edit-user');
    const canDeleteUser = hasPermission('delete-user');

    const handleUserModal = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [roleData, setRoleData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [showSuccessDeleteModal, setshowSuccessDeleteModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [editUserId, setEditUserId] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const handleEditModalClose = () => setShowEditModal(false);
    const [editFormData, setEditFormData] = useState([]);
    const [formData, setFormData] = useState({
        username: '',
        name: '',
        email: '',
        phoneNo: '',
        role: '',
    });
    
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleImageChange = (e) => {
        setFormData({
            ...formData,
            image: e.target.files[0],
        })
    };

    useEffect(() => {

        const fetchRoleData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}role/index`);
                if (response.ok) {
                    const data = await response.json();

                    setRoleData(data.roles); 
                }
            } catch (error) {
                console.error('Error fetching role data:', error);
            }
        };

        const fetchUserData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}user/index`);
                if (response.ok) {
                    const data = await response.json();
                    setUserData(data.users)
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchRoleData();
        fetchUserData();
    }, []);

    const handleSubmitUser = async (e) => {
        e.preventDefault();

        try {
            const formDataObject = new FormData();

            // Append form fields to formDataObject
            formDataObject.append('username', formData.username);
            formDataObject.append('name', formData.name);
            formDataObject.append('email', formData.email);
            formDataObject.append('phoneNo', formData.phoneNo);
            formDataObject.append('role', formData.role);

            const response = await fetch(`${API_BASE_URL}user/store`, {
                method: 'POST',
                body: formDataObject,
            });

            if (response.status == 200) {
                setShowModal(false);
                setShowSuccessModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    }

    const closeSuccessModal = () => {
        setShowSuccessModal(false);
        window.location.reload();
    };

    // Pagination Start
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = userData.slice(indexOfFirstItem, indexOfLastItem);

    const renderIndex = (index) => {
        return (currentPage - 1) * itemsPerPage + index + 1;
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };
    // Pagination end

    const handleDeleteUser = async (index) => {
        try {
            const response = await fetch(`${API_BASE_URL}user/destroy/${index}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status == 200) {
                setShowDeleteModal(false);
                setshowSuccessDeleteModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteIndex(null);
    };

    const handleConfirmDelete = (id) => {
        setDeleteIndex(id);
        setShowDeleteModal(true);
    };

    const handleEditUserModal = (userId) => {
        setEditUserId(userId);
        setShowEditModal(true);
    };

    const fetchUserById = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}user/edit/${id}`);
            if (response.ok) {
                const data = await response.json();
    
                const user = data.user;
    
                setEditFormData((prevFormData) => ({
                    ...prevFormData,
                    username: user.username || '',
                    name: user.name || '',
                    email: user.email || '',
                    phoneNo: user.phone_number || '',
                    role: user.role_id || '',
                }));
            }
        } catch (error) {
            console.error('Error fetching user:', error);
        }
    };

    useEffect(() => {
        if (editUserId !== null) {
            fetchUserById(editUserId);
        }
    }, [editUserId]);

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditFormData({
            ...editFormData,
            [name]: value,
        });
    };

    const handleUpdateUser = async (e) => {
        e.preventDefault();
    
        try {
            const formDataObject = new FormData();
    
            formDataObject.append('username', editFormData.username);
            formDataObject.append('name', editFormData.name);
            formDataObject.append('email', editFormData.email);
            formDataObject.append('phoneNo', editFormData.phoneNo);
            formDataObject.append('role', editFormData.role);

            const response = await fetch(`${API_BASE_URL}user/update/${editUserId}`, {
                method: 'POST',
                body: formDataObject,
            });
    
            if (response.ok) {
                setShowEditModal(false);
                setShowSuccessModal(true);
            }
        } catch (error) {
            // Handle fetch errors
            console.error('Error updating user:', error);
        }
    };

    return (
        <LayoutPage>
            <div className='children'>
                <div className="d-flex listTopBar top_bar p-3 bold_poppins justify-content-between">
                    <h4 className="mt-3 pg_title">
                        SENARAI PENGGUNA
                    </h4>
                </div>
                {canCreateUser &&
                    <div className="addZone mt-3 mx-3 d-flex justify-content-center mb-4" onClick={handleUserModal}>
                        <div className="icon">
                            <FontAwesomeIcon className="icon" icon={faPlus} />
                        </div>
                        <div className="text">
                            Pengguna
                        </div>
                    </div>
                }

                <div>
                    <div className='m-3 table-container table_responsive'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Nama</th>
                                    <th>Emel</th>
                                    <th>No. Telefon</th>
                                    <th>Peranan</th>
                                    {(canEditUser || canDeleteUser) && <th className='text-center'>Tindakan</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.length > 0 ? (
                                    currentItems.map((user, index) => (
                                        <tr key={index}>
                                            <td>{renderIndex(index)}</td>
                                            <td>{ user.name }</td>
                                            <td>{ user.email }</td>
                                            <td>{ user.phone_number }</td>
                                            <td>{ user.role_name }</td>
                                            {(canEditUser || canDeleteUser) && <td className='text-center'>
                                                <div className="unwrap editdelete_icon">
                                                    {canEditUser && <FontAwesomeIcon icon={faPencil} className="icon edit-icon" onClick={() => handleEditUserModal(user.id)} />}
                                                    {canDeleteUser && <FontAwesomeIcon icon={faTrash} className="icon delete-icon" onClick={() => handleConfirmDelete(user.id)}/>}
                                                </div>
                                            </td>}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6" className="text-center">Tiada data dijumpai</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className='pagination'>
                            <button onClick={handlePrevPage} disabled={currentPage === 1}>
                                Sebelum
                            </button>
                            <button onClick={handleNextPage} disabled={indexOfLastItem >= userData.length}>
                                Seterusnya
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            {/* Modal Create User */}
            <Modal className='modal_disease' show={showModal} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Tambah Pengguna Baru</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Your form content */}
                    <Form onSubmit={handleSubmitUser}>
                        <Form.Group controlId="" >
                            <Form.Label className="label-left mt-3">Nama Pengguna</Form.Label>
                            <Form.Control value={formData.username} onChange={handleInputChange} name='username' type="text" placeholder="Masukkan nama pengguna (Username)" required />
                        </Form.Group>
                        <Form.Group controlId="" >
                            <Form.Label className="label-left mt-3">Nama Penuh</Form.Label>
                            <Form.Control value={formData.name} onChange={handleInputChange} name='name' type="text" placeholder="Masukkan nama penuh" required />
                        </Form.Group>
                        <Form.Group controlId="" >
                            <Form.Label className="label-left mt-3">Emel</Form.Label>
                            <Form.Control value={formData.email} onChange={handleInputChange} name='email' type="email" placeholder="Masukkan emel" required />
                        </Form.Group>
                        <Form.Group controlId="" >
                            <Form.Label className="label-left mt-3">No. Telefon</Form.Label>
                            <Form.Control value={formData.phoneNo} onChange={handleInputChange} name='phoneNo' type="text" placeholder="Masukkan nombor telefon" required />
                        </Form.Group>
                        <Form.Group controlId="" >
                            <Form.Label className="label-left mt-3">Peranan</Form.Label>
                            <Form.Control
                                as="select"
                                value={formData.role}
                                onChange={handleInputChange}
                                name="role"
                            >
                                <option value="">Pilih peranan</option>
                                {roleData.map((option, optionIndex) => (
                                    <option key={optionIndex} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4">
                            Simpan
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal/Popup for Success Message */}
            <SuccessModal
                show={showSuccessModal}
                onHide={closeSuccessModal}
                title="Tindakan Berjaya"
                modalBody={<p>Data telah berjaya disimpan!</p>}
                buttonText="Tutup"
            />

            {/* Modal Edit User */}
            <Modal className='modal_disease' show={showEditModal} onHide={handleEditModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Kemaskini Pengguna</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Your form content */}
                    <Form onSubmit={handleUpdateUser}>
                        <Form.Group controlId="" >
                            <Form.Label className="label-left mt-3">Nama Pengguna</Form.Label>
                            <Form.Control value={editFormData.username} onChange={handleEditInputChange} name='username' type="text" placeholder="Masukkan nama pengguna" required />
                        </Form.Group>
                        <Form.Group controlId="" >
                            <Form.Label className="label-left mt-3">Nama Penuh</Form.Label>
                            <Form.Control value={editFormData.name} onChange={handleEditInputChange} name='name' type="text" placeholder="Masukkan nama penuh" required />
                        </Form.Group>
                        <Form.Group controlId="" >
                            <Form.Label className="label-left mt-3">Emel</Form.Label>
                            <Form.Control value={editFormData.email} onChange={handleEditInputChange} name='email' type="email" placeholder="Masukkan emel" required />
                        </Form.Group>
                        <Form.Group controlId="" >
                            <Form.Label className="label-left mt-3">No. Telefon</Form.Label>
                            <Form.Control value={editFormData.phoneNo} onChange={handleEditInputChange} name='phoneNo' type="text" placeholder="Masukkan nombor telefon" required />
                        </Form.Group>
                        <Form.Group controlId="" >
                            <Form.Label className="label-left mt-3">Peranan</Form.Label>
                            <Form.Control
                                as="select"
                                value={editFormData.role}
                                onChange={handleEditInputChange}
                                name="role"
                            >
                                <option value="">Pilih peranan</option>
                                {roleData.map((option, optionIndex) => (
                                    <option key={optionIndex} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4">
                            Kemaskini
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Delete Modal */}
            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Pengesahan Padam</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Adakah anda pasti ingin memadam pengguna ini?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseDeleteModal}>
                        Batal
                    </Button>
                    <Button className="delete-button" onClick={() => handleDeleteUser(deleteIndex)}>
                        Padam
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal/Popup for Confirmation Delete */}
            <SuccessModal
                show={showSuccessDeleteModal}
                onHide={closeSuccessModal}
                title="Tindakan Berjaya"
                modalBody={<p>Data telah berjaya dipadam!</p>}
                buttonText="Tutup"
            />
        </LayoutPage>
    )
}

export default ManageUserPage