import LayoutPage from "../layouts/base3"
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { API_BASE_URL, IMAGE_BASE_URL } from "../constants/apiConfig";
import { Modal, Button } from "react-bootstrap";
import SuccessModal from "../components/success-modal";
import { useAuth } from "../services/AuthProvider";

function PlantListingPage() {

    const navigate = useNavigate();

    const itemsPerPage = 10;

    const { user, hasPermission } = useAuth();

    const canManagePlant = hasPermission('manage-plant');
    const canCreatePlant  = hasPermission('create-plant');
    const canEditPlant  = hasPermission('edit-plant');
    const canDeletePlant  = hasPermission('delete-plant');

    const { tamanId } = useParams();
    const [tamanData, setTamanData] = useState([]);
    const [itemData, setItemData] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [showSuccessDeleteModal, setshowSuccessDeleteModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const tamanName = tamanData && tamanData.name ? tamanData.name.toUpperCase() : '';

    useEffect(() => {
        const fetchTamanData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}plant/index/${tamanId}`);
                if (response.ok) {
                    const data = await response.json();
                    setTamanData(data.garden)
                    setItemData(data.plants);
                    console.log(data.plants)
                }
            } catch (error) {
                console.error('Error fetching zone data:', error);
            }
        };

        fetchTamanData();
    }, []);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    }; 
    
    const filteredData = itemData.filter((item) =>
        item && item.nama && item.nama.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleConfirmDelete = (id) => {
        setDeleteIndex(id);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteIndex(null);
    };

    const handleDeleteItem = async (index) => {
        try {
            const response = await fetch(`${API_BASE_URL}plant/remove/${index}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status == 200) {
                setShowDeleteModal(false);
                setshowSuccessDeleteModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    };

    const closeSuccessModal = () => {
        setshowSuccessDeleteModal(false);
        window.location.reload();
    };

    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const renderIndex = (index) => {
        return (currentPage - 1) * itemsPerPage + index + 1;
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    return (
        <LayoutPage>
            <div className="d-flex flex-wrap listTopBar top_bar p-3 bold_poppins justify-content-between">
                <h3 className='bold_poppins'>
                    {tamanName}
                </h3>
                <div className="d-flex ">
                    {canCreatePlant && <button className="addZone " onClick={() => navigate(`/addGardenForm/${tamanId}`)}>
                        <FontAwesomeIcon className="icon mr-2" icon={faPlus} />
                        Tumbuhan
                    </button>}
                    <div className="ml-3"><Form.Control
                        placeholder="Cari..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        aria-label=""
                        aria-describedby="basic-addon1"
                    /></div>
                </div>
            </div>
            <div className="m-4">
                {itemData.length > 0 ? (
                    currentItems.map((item, index) => (
                        <div key={index} className="listBar  d-flex  p-3 my-4" onClick={() => navigate(`/viewGardenDetails/${item.id}`)} style={{ cursor: 'pointer' }}>
                            <div className="col-2">
                                {item.plant_images && item.plant_images.length > 0 && (
                                    <img
                                        src={`${IMAGE_BASE_URL}${item.plant_images[0].name}`} // Assuming imageUrl is the property holding the image URL
                                        className="img-fluid img_view "
                                        alt="Plant Image"
                                    />
                                )}
                                {/* If there are no images, you can display a placeholder */}
                                {!item.plant_images || item.plant_images.length === 0 && (
                                    <img
                                        src="/images/img_logo.png" // Placeholder image path
                                        className="img-fluid img_view mb-3"
                                        alt="Placeholder"
                                    />
                                )}
                            </div>
                            <div className="col-10">
                                <div className="d-flex justify-content-between ">
                                    <h5 className="mb-2 regular_poppins nama_item unwrap">
                                        {index + 1}. {item.nama}
                                    </h5>
                                    <div className="unwrap">
                                        {canEditPlant && <FontAwesomeIcon icon={faPencil} className="icon edit-icon" onClick={(e) => { e.stopPropagation(); navigate(`/editGardenForm/${item.id}`); }} style={{ cursor: 'pointer' }} />}
                                        {canDeletePlant && <FontAwesomeIcon icon={faTrash} className="icon delete-icon" onClick={(e) => { e.stopPropagation(); handleConfirmDelete(item.id); }} />}
                                    </div>
                                </div>
                                <div className="mt-2 regular_poppins desc_tumbuhan" style={{ textAlign: 'justify' }}>{item.penerangan}</div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="d-flex justify-content-center regular_poppins">Tiada data ditemui</div>
                )}

                <div className='pagination'>
                    <button onClick={handlePrevPage} disabled={currentPage === 1}>
                        Prev
                    </button>
                    <button onClick={handleNextPage} disabled={indexOfLastItem >= itemData.length}>
                        Next
                    </button>
                </div>
            </div>


            {/* Delete Modal */}
            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Pengesahan Padam</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Adakah anda pasti ingin memadam maklumat taman ini?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseDeleteModal}>
                        Batal
                    </Button>
                    <Button className="delete-button" onClick={() => handleDeleteItem(deleteIndex)}>
                        Padam
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal/Popup for Confirmation Delete */}
            {showSuccessDeleteModal && (
                <SuccessModal
                    show={showSuccessDeleteModal}
                    onHide={closeSuccessModal}
                    title="Tindakan Berjaya"
                    modalBody={<p>Maklumat telah berjaya dipadam!</p>}
                    buttonText="Tutup"
                />
            )}

        </LayoutPage>
    )
}

export default PlantListingPage