import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useLocation } from 'react-router-dom';
import Sidebar from '../sidebars/sidebar3';
import Dropdown from 'react-bootstrap/Dropdown';
import { logout } from '../services/auth';
import { useNavigate } from 'react-router-dom/dist';
import { API_BASE_URL, MARDI_DEV_URL } from '../constants/apiConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap } from '@fortawesome/free-solid-svg-icons';



const LayoutPage = ({ children }) => {
    const [sidebarOpen, setSidebarOpen] = useState(true);

    const location = useLocation();

    const currentPathname = window.location.pathname;
    const pathParts = currentPathname.split('/');

    let storedTamanId;
    if (pathParts.length === 4) { // Check if there are two values at the end
        storedTamanId = pathParts[pathParts.length - 2]; // Get the second-to-last value
    } else {
        storedTamanId = pathParts[pathParts.length - 1]; // Get the last value
    }

    const navigate = useNavigate();

    const mainStyle = {
        marginLeft: '0',
        height: '75vh',
        overflowY: 'auto',
    };

    // const storedTamanId = localStorage.getItem('taman_id');
    // console.log('Stored tamanId:', storedTamanId);

    return (
        <div className='base_fixed'>
            <div className="d-flex top_bar mt-3 mb-3">
                <img
                    src="/images/mardi_logo.png"
                    className="img-fluid rounded-start mardi_logo_layout"
                    alt="..."
                />
                <h2 className="big_title">TAMAN AGROTEKNOLOGI MARDI CAMERON HIGHLANDS</h2>
            </div>

            {/* <Navbar bg="dark" data-bs-theme="dark" className=''>
                <div className='justify-content-start navbar_layout'>
                    <Navbar.Brand href="#home" className='text-white'>BORANG TAMAN AGROTEKNOLOGI</Navbar.Brand>
                    <Navbar.Brand href="#home" className='text-white'>QR Generator</Navbar.Brand>

                </div>
            </Navbar> */}

            <div className='bg-red justify-content-between d-flex flex-wrap'>
                <div className='d-flex flex-wrap navbar_layout '>
                    <FontAwesomeIcon icon={faMap} className="icon" 
                        onClick={(e) => { e.stopPropagation(); navigate(`/map/${storedTamanId}`); }} 
                        style={{ cursor: 'pointer', color: '#ffffff', fontSize: '24px', marginTop: '12px', marginLeft: '5px'  }} 
                    />
                </div>

                <div className=' d-flex justify-content-center align-items-center my-3 mr-2'>
                    <a href={`${MARDI_DEV_URL}listOfPlant/${storedTamanId}`} className=' text-white  text-center  welcome_text'>Selamat Datang</a>
                </div>

            </div>

            <div className='qrPage'>
                <div id="main" className='qrPage' style={mainStyle}>
                    {children}
                </div>
            </div>

        </div>
    )
}

export default LayoutPage