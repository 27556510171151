import LayoutPage from "../layouts/base3"
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL } from "../constants/apiConfig";
import { throttle } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import SuccessModal from "../components/success-modal";
import { useAuth } from "../services/AuthProvider";


function ZoneListingPage() {

    const { user, hasPermission } = useAuth();

    const canManageZone = hasPermission('manage-zone');
    const canCreateZone = hasPermission('create-zone');
    const canEditZone = hasPermission('edit-zone');
    const canDeleteZone = hasPermission('delete-zone');

    const [zoneData, setZoneData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showSuccessDeleteModal, setshowSuccessDeleteModal] = useState(false);
    const handleModalClose = () => setShowModal(false);
    const handleZoneModal = () => setShowModal(true);
    const [namaZone, setNamaZone] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const handleEditModalClose = () => setShowEditModal(false);

    const fetchZoneData = () => {
        fetch(`${API_BASE_URL}zone/index`)
            .then((response) => response.json())
            .then((data) => {
                setZoneData(data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        const throttledFetchData = throttle(fetchZoneData, 3000);
        throttledFetchData(); // Initial fetch

        return () => {
            throttledFetchData.cancel();
        };
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setShowModal(false);
    };

    const handleNameChange = (e) => {
        setNamaZone(e.target.value);
    };

    const handleSubmitZone = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_BASE_URL}zone/store`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ namaZone }),
            });

            if (response.status == 200) {
                setShowModal(false);
                setShowSuccessModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    }

    const [editedZoneName, setEditedZoneName] = useState('');
    const [editZoneId, setEditZoneId] = useState(null);

    // Function to fetch zone data by ID
    const fetchZoneById = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}zone/edit/${id}`);
            if (response.ok) {
                const data = await response.json();
                console.log(data)
                setEditedZoneName(data.name);
            }
        } catch (error) {
            console.error('Error fetching zone:', error);
        }
    };

    useEffect(() => {
        if (editZoneId !== null) {
            fetchZoneById(editZoneId);
        }
    }, [editZoneId]);

    const handleEditedNameChange = (event) => {
        setEditedZoneName(event.target.value);
    };

    const handleEditZoneModal = (zoneId) => {
        setEditZoneId(zoneId);
        setShowEditModal(true);
    };

    const handleSubmitEditZone = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_BASE_URL}zone/update/${editZoneId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ zoneName: editedZoneName }), // Assuming the key for zone name is 'zoneName'
            });

            if (response.ok) {
                setShowEditModal(false);
                setShowSuccessModal(true);
            }
        } catch (error) {
            // Handle fetch errors
            console.error('Error updating zone:', error);
        }
    }

    const handleDeleteZone = async (index) => {
        try {
            const response = await fetch(`${API_BASE_URL}zone/remove/${index}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status == 200) {
                setShowDeleteModal(false);
                setshowSuccessDeleteModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    };

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteIndex(null);
    };

    const handleConfirmDelete = (id) => {
        setDeleteIndex(id);
        setShowDeleteModal(true);
    };

    const closeSuccessModal = () => {
        setShowSuccessModal(false);
        window.location.reload();
    };

    return (
        <LayoutPage>
            <div className="d-flex listTopBar top_bar p-3 bold_poppins justify-content-between">
                <h4 className="mt-3 pg_title">
                    SENARAI ZON
                </h4>


            </div>
            <div className="m-4">
                {/*<div className="addZone d-flex justify-content-center mb-4" onClick={handleZoneModal}>
                    <div className="icon">
                        <FontAwesomeIcon className="icon" icon={faPlus} />
                    </div>
                    <div className=" text">
                        Zon
                    </div>
                </div>*/}
                {zoneData.map((zone, index) => (
                    <div key={index} className="listBar p-3 my-3">
                        <div className="d-flex justify-content-between mx-4">
                            <div className="regular_poppins">
                                {index + 1}. {zone.name}
                            </div>
                            <div className="regular_poppins">
                                {canEditZone && <FontAwesomeIcon icon={faPencil} className="icon edit-icon" onClick={() => handleEditZoneModal(zone.id)} />}
                                {/* {canDeleteZone && <FontAwesomeIcon icon={faTrash} className="icon delete-icon" onClick={() => handleConfirmDelete(zone.id)} />} */}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Modal Create Zone */}
            <Modal show={showModal} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Tambah Zon</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Your form content */}
                    <Form onSubmit={handleSubmitZone}>
                        <Form.Group controlId="formZoneName" >
                            <Form.Label className="label-left">Nama Zon</Form.Label>
                            <Form.Control value={namaZone} onChange={handleNameChange} type="text" placeholder="Masukkan nama zon" required />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4">
                            Simpan
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal Update Zone */}
            <Modal show={showEditModal} onHide={handleEditModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Zon</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitEditZone}>
                        <Form.Group controlId="formZoneName" >
                            <Form.Label className="label-left">Nama Zon</Form.Label>
                            <Form.Control
                                value={editedZoneName}
                                onChange={handleEditedNameChange}
                                type="text"
                                placeholder="Masukkan nama zon"
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-4">
                            Kemaskini
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Delete Modal */}
            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Pengesahan Padam</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Adakah anda pasti ingin memadam zon ini?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseDeleteModal}>
                        Batal
                    </Button>
                    <Button className="delete-button" onClick={() => handleDeleteZone(deleteIndex)}>
                        Padam
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal/Popup for Success Message */}
            {showSuccessModal && (
                <SuccessModal
                    show={showSuccessModal}
                    onHide={closeSuccessModal}
                    title="Tindakan Berjaya"
                    modalBody={<p>Data telah berjaya disimpan!</p>}
                    buttonText="Tutup"
                />
            )}

            {/* Modal/Popup for Confirmation Delete */}
            {showSuccessDeleteModal && (
                <SuccessModal
                    show={showSuccessDeleteModal}
                    onHide={closeSuccessModal}
                    title="Tindakan Berjaya"
                    modalBody={<p>Data telah berjaya dipadam!</p>}
                    buttonText="Tutup"
                />
            )}


        </LayoutPage>
    )
}

export default ZoneListingPage