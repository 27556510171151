// import LayoutPage from './Base';
import { useEffect, useState } from 'react';
import LayoutPage from '../layouts/base2';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { API_BASE_URL, IMAGE_BASE_URL, AUDIO_BASE_URL } from '../constants/apiConfig';
import { throttle } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faA, faAngleDown, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import SuccessModal from '../components/success-modal';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, Button } from "react-bootstrap";

function EditBorangTamanPage() {

    var title_page = 'kemaskini maklumat taman agroteknologi';

    const navigate = useNavigate();

    const { plantId } = useParams();
    const [tamanData, setTamanData] = useState([]);
    const [itemData, setItemData] = useState([]);
    const [itemImages, setItemImages] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [namaTamanOptions, setNamaTamanOptions] = useState([]);
    const [zonOptions, setZonOptions] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [formData, setFormData] = useState({
        namaTaman: '',
        zon: '',
        namaItem: '',
        namaSaintifik: '',
        penerangan: '',
        ciri_ciri: '',
        jenis: '',
        spesies: '',
        penjagaan: '',
        url: '',
        galeri: [],
    });

    useEffect(() => {
        const fetchZoneData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}plant/edit/${plantId}`);
                if (response.ok) {
                    const data = await response.json();
                    setItemData(data.plant);
                    setTamanData(data.garden);
                    setNamaTamanOptions(data.gardenData);
                    setZonOptions(data.zone);
                    if (data.plant && data.plant.plant_images) {
                        setItemImages(data.plant.plant_images);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchZoneData();
    }, [plantId]);

    useEffect(() => {
        if (tamanData.id) {
            const selectedTaman = namaTamanOptions.find((taman) => taman.id === parseInt(tamanData.id));

            if (selectedTaman) {
                const selectedZone = zonOptions.find((zone) => zone.id === parseInt(selectedTaman.zone_id));
                setFormData({
                    ...formData,
                    namaTaman: tamanData.id,
                    zon: selectedZone ? selectedZone.name : '',
                });
            }
        }
    }, [formData.namaTaman, namaTamanOptions, zonOptions]);

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);

        const images = files.map((file) => ({
            file,
            preview: URL.createObjectURL(file),
        }));

        setSelectedImages([...selectedImages, ...images]);
        setFormData({
            ...formData,
            galeri: [...formData.galeri, ...files],
        });
    }

    const handleRemoveImage = (indexToRemove) => {
        const updatedImages = selectedImages.filter(
            (_, index) => index !== indexToRemove
        );
        setSelectedImages(updatedImages);

        const updatedFiles = formData.galeri.filter(
            (_, index) => index !== indexToRemove
        );
        setFormData({
            ...formData,
            galeri: updatedFiles,
        });
    }

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [showSuccessDeleteModal, setshowSuccessDeleteModal] = useState(false);

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteIndex(null);
    };

    const handleConfirmDelete = (id) => {
        setDeleteIndex(id);
        setShowDeleteModal(true);
    };

    const closeSuccessModal = () => {
        setShowSuccessModal(false);
        window.location.reload();
    };

    const handleRemoveExistImage = async (index) => {
        try {
            const response = await fetch(`${API_BASE_URL}plant/removeImage/${index}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                setShowDeleteModal(false);
                setshowSuccessDeleteModal(true);
            }

        } catch (error) {
            // Handle fetch errors
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        if (itemData && Object.keys(itemData).length > 0) {
            setFormData({
                namaItem: itemData.nama || '',
                namaSaintifik: itemData.nama_saintifik || '',
                penerangan: itemData.penerangan || '',
                jenis: itemData.jenis || '',
                spesies: itemData.spesis || '',
                ciriCiri: itemData.ciri_ciri || '',
                penjagaan: itemData.penjagaan || '',
                url: itemData.url || '',
                galeri: [],
            });
        }
    }, [itemData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAudioChange = (e) => {
        const file = e.target.files[0]; // Get the selected audio file
        setFormData({
            ...formData,
            audioFile: file, // Update the state with the selected audio file
        });
    };


    const handleUpdateTumbuhan = async (e) => {
        e.preventDefault();

        if (!showDeleteModal) {
            try {
                const formDataWithFiles = new FormData();

                Object.entries(formData).forEach(([key, value]) => {
                    if (key !== 'galeri') {
                        formDataWithFiles.append(key, value);
                    }
                });

                if (formData.galeri && formData.galeri.length > 0) {
                    formData.galeri.forEach((file, index) => {
                        formDataWithFiles.append(`galeri[${index}]`, file);
                    });
                }

                // Append the new audio file
                if (formData.audioFile) {
                    formDataWithFiles.append('audioFile', formData.audioFile);
                }

                const response = await fetch(`${API_BASE_URL}plant/update/${plantId}`, {
                    method: 'POST',
                    body: formDataWithFiles,
                });

                const data = await response.json();
                console.log(response.status)

                if (response.status === 200) {
                    setShowSuccessModal(true);
                }

            } catch (error) {
                // Handle fetch errors
                console.error('Error:', error);
            }
        }
    };



    return (
        <LayoutPage>
            <div class="m-3">
                <form onSubmit={handleUpdateTumbuhan}>
                    <h3 className='bold_poppins mt-3 mb-5'>{title_page.toUpperCase()}</h3>
                    <div class="row mb-3 mt-1">
                        <div class="col-md-2 ">
                            <Form.Label className="label_control" htmlFor="basic-url">Nama Taman:</Form.Label>
                        </div>
                        <div class="col-md-7">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    as="select"
                                    id="namaTaman"
                                    name="namaTaman"
                                    value={tamanData.id}
                                    onChange={handleInputChange}
                                    aria-describedby="basic-addon3"
                                    disabled
                                >
                                    <option value="">Pilih..</option>
                                    {namaTamanOptions.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </InputGroup>
                        </div>
                        <div class="col-md-1 ">
                            <Form.Label className="label_control" htmlFor="basic-url">Zon:</Form.Label>
                        </div>
                        <div class="col-md-2">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    value={formData.zon}
                                    onChange={handleInputChange}
                                    id="zon"
                                    name="zon"
                                    aria-describedby="basic-addon3"
                                    disabled
                                />
                            </InputGroup>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-2 ">
                            <Form.Label className="label_control" htmlFor="basic-url">Nama Item:</Form.Label>
                        </div>
                        <div class="col-md-5">
                            <InputGroup className="mb-3">
                                <Form.Control value={formData.namaItem} onChange={handleInputChange} name='namaItem' id="basic-url" aria-describedby="basic-addon3" required />
                            </InputGroup>
                        </div>
                        <div class="col-md-2 ">
                            <Form.Label className="label_control" htmlFor="basic-url">Nama Saintifik:</Form.Label>
                        </div>
                        <div class="col-md-3">
                            <InputGroup className="mb-3">
                                <Form.Control value={formData.namaSaintifik} onChange={handleInputChange} name='namaSaintifik' id="basic-url" aria-describedby="basic-addon3" />
                            </InputGroup>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-2 ">
                            <Form.Label className="label_control" htmlFor="basic-url">Penerangan:</Form.Label>
                        </div>
                        <div class="col-md-10">
                            <InputGroup className="mb-3">
                                <Form.Control value={formData.penerangan} onChange={handleInputChange} as="textarea" name='penerangan' id="basic-url" aria-describedby="basic-addon3" required />
                            </InputGroup>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-2 ">
                            <Form.Label className="label_control" htmlFor="basic-url">Galeri:</Form.Label>
                        </div>
                        <div class="col-md-10">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    type="file"
                                    multiple
                                    onChange={handleImageChange}
                                    name="galeri"
                                    id='galeri'
                                    aria-describedby='basic-addon3'
                                />
                            </InputGroup>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-2"></div>
                        <div class="col-md-6 image-container">
                            {selectedImages.map((image, index) => (
                                <div key={index} className='preview-container'>
                                    <img
                                        src={image.preview}
                                        alt={`Preview-${index}`}
                                        className='preview-image'
                                    />
                                    <div>
                                        <button
                                            onClick={() => handleRemoveImage(index)}
                                            className="remove-button"
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </button>
                                    </div>

                                </div>
                            ))}
                            {itemImages.map((image, index) => (
                                <div key={index} className='preview-container'>
                                    <img
                                        src={`${IMAGE_BASE_URL}${image.name}`}
                                        alt={`Preview-${index}`}
                                        className='preview-image'
                                    />
                                    <div>
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleConfirmDelete(image.id);
                                            }}
                                            className="remove-button"
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </button>
                                    </div>

                                </div>
                            ))}
                        </div>
                        <div class="col-md-4">
                            <div class="row mb-3 justify-content-end">
                                <div class="col-md-4 text-left">
                                    <Form.Label className="label_control" htmlFor="basic-url">Jenis:</Form.Label>
                                </div>
                                <div class="col-md-7">
                                    <InputGroup className="mb-3">
                                        <Form.Control value={formData.jenis} onChange={handleInputChange} name='jenis' id="basic-url" aria-describedby="basic-addon3" required />
                                    </InputGroup>
                                </div>
                            </div>
                            <div class="row mb-3 justify-content-end">
                                <div class="col-md-4 text-left">
                                    <Form.Label className="label_control" htmlFor="basic-url">Spesies:</Form.Label>
                                </div>
                                <div class="col-md-7">
                                    <InputGroup className="mb-3">
                                        <Form.Control value={formData.spesies} onChange={handleInputChange} name='spesies' id="basic-url" aria-describedby="basic-addon3" required />
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-md-2 ">
                            <Form.Label className="label_control" htmlFor="basic-url">Ciri-ciri:</Form.Label>
                        </div>
                        <div class="col-md-10">
                            <InputGroup className="mb-3">
                                <Form.Control value={formData.ciriCiri} onChange={handleInputChange} name='ciriCiri' as="textarea" id="basic-url" aria-describedby="basic-addon3" required />
                            </InputGroup>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-2 ">
                            <Form.Label className="label_control" htmlFor="basic-url">Penjagaan Tumbuhan:</Form.Label>
                        </div>
                        <div class="col-md-10">
                            <InputGroup className="mb-3">
                                <Form.Control value={formData.penjagaan} onChange={handleInputChange} name='penjagaan' as="textarea" id="basic-url" aria-describedby="basic-addon3" required />
                            </InputGroup>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-2 ">
                            <Form.Label className="label_control" htmlFor="basic-url">URL:</Form.Label>
                        </div>
                        <div class="col-md-10">
                            <InputGroup className="mb-3">
                                <Form.Control value={formData.url} onChange={handleInputChange} name='url' id="basic-url" aria-describedby="basic-addon3" />
                            </InputGroup>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-2 ">
                            <Form.Label className="label_control" htmlFor="basic-url">Audio:</Form.Label>
                        </div>
                        <div class="col-md-10">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    type="file"
                                    accept="audio/*"
                                    onChange={handleAudioChange}
                                    name="audioFile"
                                    id='audioFile'
                                    aria-describedby='basic-addon3'
                                />
                            </InputGroup>
                            {/* Display existing audio file */}
                            {itemData.audio && (
                                <div>
                                    <audio controls>
                                        <source src={`${AUDIO_BASE_URL}${itemData.audio}`} type="audio/mpeg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className=' mt-5 mb-5 text-center'>
                        <button type='submit' className='btn formSimpanBtn'>Kemaskini</button>
                        <button className='btn formBatalBtn' onClick={() => navigate(`/plant/${tamanData.id}`)}>Batal</button>
                    </div>
                </form>

                {/* Delete Modal */}
                <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Pengesahan Padam</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Adakah anda pasti ingin memadam gambar ini?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleCloseDeleteModal}>
                            Batal
                        </Button>
                        <Button className="delete-button" onClick={() => handleRemoveExistImage(deleteIndex)}>
                            Padam
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Modal/Popup for Confirmation Delete */}
                {showSuccessDeleteModal && (
                    <SuccessModal
                        show={showSuccessDeleteModal}
                        onHide={closeSuccessModal}
                        title="Tindakan Berjaya"
                        modalBody={<p>Gambar telah berjaya dipadam!</p>}
                        buttonText="Tutup"
                    />
                )}

                {/* Modal/Popup for Success Message */}
                {showSuccessModal && (
                    <SuccessModal
                        show={showSuccessModal}
                        onHide={closeSuccessModal}
                        title="Tindakan Berjaya"
                        modalBody={<p>Data telah berjaya dikemaskini!</p>}
                        buttonText="Tutup"
                    />
                )}
            </div>
        </LayoutPage>
    )
}

export default EditBorangTamanPage