import LayoutPage from "../layouts/base4"
import Form from 'react-bootstrap/Form';
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { API_BASE_URL, IMAGE_BASE_URL } from "../constants/apiConfig";

function PublicPlantListingPage() {

    const navigate = useNavigate();

    const { tamanId } = useParams();
    const [tamanData, setTamanData] = useState([]);
    const [itemData, setItemData] = useState([]);

    const tamanName = tamanData && tamanData.name ? tamanData.name.toUpperCase() : '';

    useEffect(() => {
        const fetchTamanData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}plant/index/${tamanId}`);
                if (response.ok) {
                    const data = await response.json();
                    setTamanData(data.garden)
                    setItemData(data.plants);
                    console.log(data.plants)
                }
            } catch (error) {
                console.error('Error fetching zone data:', error);
            }
        };

        fetchTamanData();
    }, []);


    return (
        <LayoutPage>
            <div className="d-flex listTopBar top_bar p-3 bold_poppins justify-content-between">
                <h3 className='bold_poppins pl-2 pg_title'>
                    {tamanName}
                </h3>
                <div className="d-flex">
                    <div className="ml-3"><Form.Control
                        placeholder="Cari..."
                        aria-label=""
                        aria-describedby="basic-addon1"
                    /></div>
                </div>
            </div>
            <div className="m-4">
                {itemData.length > 0 ? (
                    itemData.map((item, index) => (
                        <div key={index} className="listBar d-flex p-2 my-4" onClick={() => navigate(`/plantDetails/${tamanData.id}/${item.id}`)} style={{ cursor: 'pointer' }}>
                            <div className="col-2 mt-3">
                                {item.plant_images && item.plant_images.length > 0 && (
                                    <img
                                        src={`${IMAGE_BASE_URL}${item.plant_images[0].name}`} // Assuming imageUrl is the property holding the image URL
                                        className="img-fluid img_view mb-3"
                                        alt="Plant Image"
                                    />
                                )}
                                {/* If there are no images, you can display a placeholder */}
                                {!item.plant_images || item.plant_images.length === 0 && (
                                    <img
                                        src="/images/img_logo.png" // Placeholder image path
                                        className="img-fluid img_view mb-3"
                                        alt="Placeholder"
                                    />
                                )}
                            </div>
                            <div className="col-10">
                                <div className="d-flex justify-content-between mt-3">
                                    <h5 className="mb-2 regular_poppins">
                                        {index + 1}. {item.nama}
                                    </h5>
                                </div>
                                <div className="mt-2 regular_poppins desc_tumbuhan" style={{ textAlign: 'justify' }}>{item.penerangan}</div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="d-flex justify-content-center regular_poppins">Tiada data ditemui</div>
                )}
            </div>

        </LayoutPage>
    )
}

export default PublicPlantListingPage